import {
  Android2Icon,
  Grid,
  IosIcon,
  Option,
  Select,
  Typography,
  WebIcon,
} from "@flowriver/ui-kit";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platforms } from "src/constants/Platforms";

type Props = {
  onChange: (value: Platforms) => void;
  size: "small" | "medium";
};

type Option = {
  value: Platforms;
  label: string;
};

const PlatformItem: FC<{
  value: Platforms;
  label: string;
  icon: JSX.Element;
}> = ({ value, label, icon }) => (
  <Option value={value}>
    <Grid container gap={2} flexWrap="nowrap" alignItems="center">
      {icon}
      <Typography variant="body1">{label}</Typography>
    </Grid>
  </Option>
);

export const PlatformSelect: FC<Props> = ({ size, onChange }) => {
  const { t } = useTranslation();

  const [platform, setPlatform] = useState<Platforms>(Platforms.ANDROID);

  const platforms = [
    {
      value: Platforms.ANDROID,
      label: t("shared.report_input.platforms.android"),
      icon: <Android2Icon sx={{ color: "common.blue" }} />,
    },
    {
      value: Platforms.IOS,
      label: t("shared.report_input.platforms.ios"),
      icon: <IosIcon />,
    },
    {
      value: Platforms.WEB,
      label: t("shared.report_input.platforms.web"),
      icon: <WebIcon />,
    },
  ];

  useEffect(() => {
    onChange(platform);
  }, [platform]);

  return (
    <Select
      id="platform"
      size={size}
      value={platform}
      onChange={(_, value) => setPlatform(value as Platforms)}
      renderValue={(option: unknown) => {
        const value = (option as Option)?.value;
        const platform = platforms.find((p) => p.value === value);

        if (!platform) {
          return null;
        }
        return (
          <Grid container gap={2} flexWrap="nowrap" alignItems="center">
            {platform.icon}
            <Typography variant={size === "medium" ? "subtitle3" : "body2"}>
              {platform.label}
            </Typography>
          </Grid>
        );
      }}
      sx={{
        borderColor: "transparent",
        minWidth: 113,
        pr: 5,
        py: size === "medium" ? 2 : 1,
      }}
    >
      {platforms.map(({ value, label, icon }) => (
        <PlatformItem key={value} value={value} label={label} icon={icon} />
      ))}
    </Select>
  );
};
