import { AnalyticsProvider } from "@flowriver/analytics";
import { CssBaseline } from "@flowriver/ui-kit";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { GTMInitializer } from "src/components/GTMInitializer/GTMInitializer";
import { StrictMode } from "src/components/StrictMode";
import { ThemeSwitchProvider } from "src/components/ThemeSwitchProvider";
import { queryClient } from "src/configs/queryClient";
import { router } from "src/configs/router";
import { AMPLITUDE_API_KEY, IS_PROD } from "src/constants/environments";

import("src/configs/sentry");
import "@xyflow/react/dist/style.css";
import "src/configs/i18n";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    {IS_PROD ? <GTMInitializer /> : null}

    <ThemeSwitchProvider>
      <QueryClientProvider client={queryClient}>
        <AnalyticsProvider
          amplitudeApiKey={AMPLITUDE_API_KEY}
          isProduction={IS_PROD}
        >
          <CssBaseline />
          <RouterProvider router={router} />
          <SnackbarProvider />
        </AnalyticsProvider>
      </QueryClientProvider>
    </ThemeSwitchProvider>

    {IS_PROD ? (
      <>
        <script id="facebook-pixel" defer>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '382480401584177');
          fbq('track', 'PageView');
        `}
        </script>
        <noscript>
          <img
            alt="facebook-pixel"
            height="1"
            width="1"
            style={{ display: "none" }}
            src={`https://www.facebook.com/tr?id=382480401584177&ev=PageView&noscript=1`}
          />
        </noscript>
      </>
    ) : null}
  </StrictMode>,
);
